import _path from "path";
import _module from "module";
import _fs from "fs";
var exports = {};
const path = _path;
const Module = _module;
const fs = _fs;

const resolveFrom = (fromDir, moduleId, silent) => {
  if (typeof fromDir !== "string") {
    throw new TypeError(`Expected \`fromDir\` to be of type \`string\`, got \`${typeof fromDir}\``);
  }

  if (typeof moduleId !== "string") {
    throw new TypeError(`Expected \`moduleId\` to be of type \`string\`, got \`${typeof moduleId}\``);
  }

  try {
    fromDir = fs.realpathSync(fromDir);
  } catch (err) {
    if (err.code === "ENOENT") {
      fromDir = path.resolve(fromDir);
    } else if (silent) {
      return null;
    } else {
      throw err;
    }
  }

  const fromFile = path.join(fromDir, "noop.js");

  const resolveFileName = () => Module._resolveFilename(moduleId, {
    id: fromFile,
    filename: fromFile,
    paths: Module._nodeModulePaths(fromDir)
  });

  if (silent) {
    try {
      return resolveFileName();
    } catch (err) {
      return null;
    }
  }

  return resolveFileName();
};

exports = (fromDir, moduleId) => resolveFrom(fromDir, moduleId);

exports.silent = (fromDir, moduleId) => resolveFrom(fromDir, moduleId, true);

export default exports;
export const silent = exports.silent;